import { ExpandIcon, Table } from './styledComponents';
import React, { FC, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Money from '../Money/Money';
import PriceByStatus from './PriceByStatus';
import messages from 'containers/OrdersPage/messages';
import styled from 'styled-components';
import utilsMessages from 'utils/messages';
import ItemQuantity from './ItemQuantity';
import { Order, OrderItem } from 'types/schema';
import { ColumnProps } from 'antd/lib/table';
import generateProductSlug from 'utils/generateProductSlug';

const NoteContainer = styled.div`
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;
  margin-top: 10px;
  position: relative;
`;

const NoteHeader = styled.div`
  background-color: #2e904e;
  padding: 8px 10px;
  color: white;
`;

const NoteBody = styled.div`
  padding: 8px 10px;
  background-color: rgba(84, 164, 110, 0.2);
`;

const Note = styled.p`
  color: #8c8c8c;
`;

interface NoteItemProps {
  item: any;
}

const NoteItem = ({ item }: NoteItemProps) => {
  const [shouldExpandNote, setshouldExpandNote] = useState(false);
  const onClickExpandItemNote = (event) => setshouldExpandNote(!shouldExpandNote);

  return (
    <NoteContainer onClick={onClickExpandItemNote}>
      <ExpandIcon>
        {shouldExpandNote ? <UpOutlined style={{ color: 'white' }} /> : <DownOutlined style={{ color: 'white' }} />}
      </ExpandIcon>
      <NoteHeader>
        <FormattedMessage {...messages.notes} />
      </NoteHeader>
      {shouldExpandNote && (
        <NoteBody>
          <p>{item.deliveredNote}</p>
        </NoteBody>
      )}
    </NoteContainer>
  );
};

interface MobileTableProps {
  order: Order;
}

const MobileTable: FC<MobileTableProps> = ({ order: { items, status } }) => {
  const lang = localStorage.getItem('lang') || 'vi';
  const mobileColumns: ColumnProps<OrderItem>[] = [
    {
      dataIndex: 'products',
      key: 'products',
      render: (_text, record) => {
        const {
          product: { name, vat, uomLocal, supplierInternalCode },
          buyerNote,
          price,
          orderedPrice,
          orderedQuantity,
          deliveryQuantity,
          total,
        } = record;
        return (
          <div style={{ textTransform: 'capitalize' }}>
            <Link
              style={{ color: 'rgba(0, 0, 0, 0.87)' }}
              to={`/products/${generateProductSlug(name, supplierInternalCode, lang)}`}
              title={name || ''}
            >
              {name}
            </Link>
            {buyerNote && <Note>{buyerNote}</Note>}
            <p>
              <span className="label">
                <FormattedMessage {...utilsMessages.price} />:
              </span>
              <PriceByStatus orderedPrice={orderedPrice || ''} deliveredPrice={price || ''} status={status} />
            </p>
            <p>
              <span className="label">
                <FormattedMessage {...utilsMessages.quantity} />:
              </span>
              <ItemQuantity
                orderedQuantity={Number(orderedQuantity)}
                deliveryQuantity={Number(deliveryQuantity)}
                uom={uomLocal || ''}
              />
            </p>
            <p>
              <span className="label">
                <FormattedMessage {...utilsMessages.totalInclVAT} />:
              </span>
              <span>
                <Money>{total || 0}</Money>
                <span className="label">
                  {' '}
                  (VAT {vat}
                  %)
                </span>
              </span>
            </p>
            {record.deliveredNote && <NoteItem item={record} />}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table
        dataSource={items}
        columns={mobileColumns}
        rowKey={(record: OrderItem) => {
          return record.product.id;
        }}
        pagination={false}
        showHeader={false}
      />
    </>
  );
};

export default MobileTable;
