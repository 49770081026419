import React, { FC } from 'react';
import styled from 'styled-components';

interface IQuantity {
  deliveryQuantity: number;
  orderedQuantity: number;
}

interface IItemQuantity extends IQuantity {
  uom: string;
}

const ColorQuantity = styled.span<IQuantity>`
  color: ${(prop: IQuantity) => (prop.deliveryQuantity >= prop.orderedQuantity ? 'rgba(0,0,0,0.87)' : '#FF4852')};
`;

export const ItemQuantity: FC<IItemQuantity> = (props) => {
  const { deliveryQuantity, orderedQuantity, uom } = props;

  if (deliveryQuantity === -1) {
    return (
      <span>
        {orderedQuantity} {uom}
      </span>
    );
  }
  return (
    <ColorQuantity deliveryQuantity={deliveryQuantity} orderedQuantity={orderedQuantity}>
      {`${deliveryQuantity}/${orderedQuantity}`} {uom}
    </ColorQuantity>
  );
};

export default ItemQuantity;
