import { message } from 'antd';
import {
  approveOrder,
  confirmOrder,
  createReOrder,
  declineOrder,
  getOrder,
  updateDeliveryItem,
  updateOrderPending,
  deleteOrderPending,
  updateOrderStatusToCancel,
  updateUnitPrice,
} from 'utils/apollo';
import {
  approveOrder as approveOrderActions,
  cancelOrder as cancelOrderActions,
  confirmOrder as confirmOrderActions,
  disapproveOrder as disapproveOrderActions,
  order as orderActions,
  reOrder as reOrderActions,
  updateDelivery as updateDeliveryActions,
  updateOrder as updateOrderActions,
} from './actions';
import selectOrderDetailsPage from './selectors';
import { call, fork, put, select, take } from 'redux-saga/effects';
import { createMatchSelector, push } from 'connected-react-router';

import ActionTypes from './constants';
import { selectCountPendingOrder } from 'containers/MainLayout/selectors';
import {
  updateItemCart,
  recentOrders as recentOrdersMainLayout,
  markNotificationAsRead,
} from 'containers/MainLayout/actions';
import messages from './messages';
import translations from 'translations';

function* initData() {
  yield put(orderActions.request());
  const route = yield select(createMatchSelector('/orders/:id'));
  const messageId = new URLSearchParams(window.location.search).get('messageId');
  if (messageId) {
    yield put(markNotificationAsRead({ messageId: messageId }));
  }
  const response = yield call(getOrder, {
    id: route.params.id,
  });
  if (response.errors) {
    yield put(orderActions.failure(response.errors));
  } else {
    yield put(orderActions.success(response));
  }
}

function* cancelOrderFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.CANCEL_ORDER);
    yield put(cancelOrderActions.request());

    const response = yield call(updateOrderStatusToCancel, payload);
    if (!response.errors) {
      yield put(cancelOrderActions.success(response.order));
    } else {
      yield put(cancelOrderActions.failure(response.errors));
    }
  }
}

function* reOrderFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.REORDER);
    yield put(reOrderActions.request());

    const response = yield call(createReOrder, payload);
    if (!response.errors) {
      yield put(reOrderActions.success(response));
      yield put(updateItemCart.success(response.cart));
      yield put(push('/checkout'));
    } else {
      yield put(reOrderActions.failure(response.errors));
    }
  }
}

function* confirmOrderFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.CONFIRM_ORDER);
    yield put(confirmOrderActions.request());

    const response = yield call(confirmOrder, payload);

    if (!response.errors) {
      yield put(confirmOrderActions.success(response.orderReceive.order));
    } else {
      yield put(confirmOrderActions.failure(response.errors));
    }
  }
}

function* updateDeliveryFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.UPDATE_DELIVERY_SUBMIT);
    yield put(updateDeliveryActions.request());

    yield call(updateUnitPrice, {
      input: payload.unitPriceInput,
    });

    const response = yield call(updateDeliveryItem, {
      input: payload.quantityInput,
    });

    if (!response.errors) {
      yield put(updateDeliveryActions.success(response.order));
    } else {
      yield put(updateDeliveryActions.failure(response.errors));
    }
  }
}

function* approveOrderFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.APPROVE_ORDER_REQUEST);
    const response = yield call(approveOrder, payload);
    if (!response.errors) {
      const countPending = yield select(selectCountPendingOrder());
      yield put(recentOrdersMainLayout.success({ countPendingOrders: countPending - 1 }));
      yield put(approveOrderActions.success(response.order));
    } else {
      yield put(approveOrderActions.failure(response.errors));
      message.error(response.errors[0].message);
    }
  }
}

function* declineOrderFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.DISAPPROVE_ORDER_REQUEST);
    const response = yield call(declineOrder, payload);
    if (!response.errors) {
      const countPending = yield select(selectCountPendingOrder());
      yield put(recentOrdersMainLayout.success({ countPendingOrders: countPending - 1 }));
      yield put(disapproveOrderActions.success(response.order));
      message.success(translations(messages.disapprovalSuccess));
    } else {
      yield put(disapproveOrderActions.failure(response.errors));
      message.error(translations(messages.declineFailed));
    }
  }
}

function* updateOrder() {
  while (true) {
    const {
      payload: { editedOrderItems, deletedOrderItems, orderId },
    } = yield take(ActionTypes.UPDATE_ORDER_REQUEST);
    const {
      order: { items },
    } = yield select(selectOrderDetailsPage());
    const dataDelete = Object.keys(deletedOrderItems);
    const dataEdit = Object.keys(editedOrderItems).reduce((result, item) => {
      const newItem = {
        itemId: item,
        quantity: editedOrderItems[item],
        note: (items.find((item) => item.product.id === item) || {}).note || '',
      };
      return [...result, newItem];
    }, []);
    const editResponse = yield dataEdit.length &&
      call(updateOrderPending, { input: { orderId: orderId, items: dataEdit } });
    const delResponse = yield dataDelete.length &&
      call(deleteOrderPending, { input: { orderId: orderId, itemIds: dataDelete } });

    if (!editResponse.errors && !delResponse.errors) {
      const response = delResponse || editResponse;
      yield put(updateOrderActions.success(response.order));
      message.success(translations(messages.updateOrderSuccess));
      if (dataDelete.length === items.length) {
        yield put(disapproveOrderActions.request({ orderId: orderId }));
      }
    } else {
      message.error(translations(messages.updateOrderFailed));
    }
  }
}

export default function* orderDetailsPageSaga() {
  yield fork(initData);
  yield fork(cancelOrderFlow);
  yield fork(reOrderFlow);
  yield fork(confirmOrderFlow);
  yield fork(updateDeliveryFlow);
  yield fork(approveOrderFlow);
  yield fork(declineOrderFlow);
  yield fork(updateOrder);
}
