/*
 *
 * OrderDetailsPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const order = createAsyncAction(
  ActionTypes.FETCH_ORDER_REQUEST,
  ActionTypes.FETCH_ORDER_SUCCESS,
  ActionTypes.FETCH_ORDER_FAILURE,
)<void, {}, Error>();

export const cancelOrderSubmit = (data: any) => action(ActionTypes.CANCEL_ORDER, data);
export const cancelOrder = createAsyncAction(
  ActionTypes.CANCEL_ORDER_REQUEST,
  ActionTypes.CANCEL_ORDER_SUCCESS,
  ActionTypes.CANCEL_ORDER_FAILURE,
)<void, {}, Error>();

export const reOrderSubmit = (data: any) => action(ActionTypes.REORDER, data);

export const reOrder = createAsyncAction(
  ActionTypes.REORDER_REQUEST,
  ActionTypes.REORDER_SUCCESS,
  ActionTypes.REORDER_FAILURE,
)<void, {}, Error>();

export const confirmOrderSubmit = (data: any) => action(ActionTypes.CONFIRM_ORDER, data);

export const confirmOrder = createAsyncAction(
  ActionTypes.CONFIRM_ORDER_REQUEST,
  ActionTypes.CONFIRM_ORDER_SUCCESS,
  ActionTypes.CONFIRM_ORDER_FAILURE,
)<void, {}, Error>();

export const updateDeliverySubmit = (data: any) => action(ActionTypes.UPDATE_DELIVERY_SUBMIT, data);

export const updateDelivery = createAsyncAction(
  ActionTypes.UPDATE_DELIVERY_REQUEST,
  ActionTypes.UPDATE_DELIVERY_SUCCESS,
  ActionTypes.UPDATE_DELIVERY_FAILURE,
)<void, {}, Error>();

export const approveOrder = createAsyncAction(
  ActionTypes.APPROVE_ORDER_REQUEST,
  ActionTypes.APPROVE_ORDER_SUCCESS,
  ActionTypes.APPROVE_ORDER_FAILURE,
)<{}, {}, Error>();

export const disapproveOrder = createAsyncAction(
  ActionTypes.DISAPPROVE_ORDER_REQUEST,
  ActionTypes.DISAPPROVE_ORDER_SUCCESS,
  ActionTypes.DISAPPROVE_ORDER_FAILURE,
)<{}, {}, Error>();

export const updateOrder = createAsyncAction(
  ActionTypes.UPDATE_ORDER_REQUEST,
  ActionTypes.UPDATE_ORDER_SUCCESS,
  ActionTypes.UPDATE_ORDER_FAILURE,
)<{}, {}, Error>();
