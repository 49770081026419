import { defineMessages } from "react-intl";

const scope = "app.containers.NotAuthorizedPage";

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Not Authorized"
  },
  heading: {
    id: `${scope}.heading`,
    defaultMessage: "Oops! Bạn không có quyền truy cập vào trang này."
  },
  backToMarketButton: {
    id: `${scope}.backToMarketButton`,
    defaultMessage: "Vào chợ"
  },
  homeButton: {
    id: `${scope}.homeButton`,
    defaultMessage: "Về trang chủ"
  }
});