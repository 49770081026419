/**
 *
 * UserTable
 *
 */

import * as React from 'react';

import { DeleteOutlined } from '@ant-design/icons';

import { InputNumber, Table } from 'antd';

import formatMoney from 'utils/formatMoney';
import styled from 'styled-components';
import translations from 'translations';
import utilsMessages from 'utils/messages';

// import styled from 'styles/styled-components';

interface OwnProps {
  items: any;
  editedOrderItems: {};
  onSetQuantity: (data) => void;
  onDeleteItem: (data) => void;
}

const Container = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  .ant-table tbody tr {
    cursor: pointer;
  }
  .ant-table-thead > tr > th {
    background: #f6ffed;
  }
  tbody {
    background-color: white;
  }
`;

const Text = styled.p`
  font-weight: 300;
`;
const Note = styled.p`
  font-weight: 300;
  color: #8c8c8c;
`;

const OrderItemTable = (props: OwnProps) => {
  const columnsConfig = [
    {
      title: translations(utilsMessages.itemName),
      key: 'product',
      dataIndex: 'product',
      render: (product, item) => {
        return (
          <>
            <Text>{product.name}</Text>
            {item.buyerNote && <Note>{item.buyerNote}</Note>}
          </>
        );
      },
      width: 460,
    },
    {
      title: translations(utilsMessages.unitPrice),
      key: 'product',
      dataIndex: 'product',
      render: (product) => (
        <Text>
          {formatMoney(product.price)}/{product.uom}
        </Text>
      ),
    },
    {
      title: translations(utilsMessages.quantity),
      key: 'quantity',
      render: (item) => (
        <InputNumber
          size="small"
          min={0.001}
          value={props.editedOrderItems[item.product.id] || item.orderedQuantity}
          onChange={(value) => props.onSetQuantity({ quantity: value, itemId: item.product.id })}
        />
      ),
    },
    {
      title: '',
      key: 'deleteItem',
      render: (item) => <DeleteOutlined onClick={() => props.onDeleteItem(item.product.id)} />,
      width: 60,
    },
  ];

  return (
    <Container>
      <Table dataSource={props.items} columns={columnsConfig} pagination={false} />
    </Container>
  );
};

export default OrderItemTable;
