/**
 *
 * OrderStatus
 *
 */
import React, { memo } from 'react';
import styled from 'styles/styled-components';
import { getStatusColor } from 'components/OrderTable/StatusIcon';

const Tag = styled.div<any>`
  border-radius: 10px;
  background-color: ${({ bg }) => bg};
  color: white;
  display: inline-block;
  padding: 2px 16px;
  font-size: 14px;
  min-width: 120px;
  text-align: center;
`;

interface OwnProps {
  status: string;
  statusText: string;
}

const OrderStatus: React.FC<OwnProps> = (props: OwnProps) => {
  return <Tag bg={getStatusColor(props.status)}>{props.statusText}</Tag>;
};

export default memo(OrderStatus);
