import Money from '../Money/Money';
import React from 'react';

interface OrderItemPriceProps {
  orderedPrice: string;
  deliveredPrice: string;
  status: string;
}

export default React.memo(({ orderedPrice, deliveredPrice, status }: OrderItemPriceProps) => {
  if (Number(orderedPrice) === Number(deliveredPrice)) {
    return <Money>{Number(orderedPrice)}</Money>;
  }

  return (
    <span>
      <Money type="old">{Number(orderedPrice)}</Money> &gt; <Money type="new">{Number(deliveredPrice)}</Money>
    </span>
  );
});
