import React from 'react';
import { Row, Card, InputNumber } from 'antd';
import Money from 'components/Money/Money';
import media from 'utils/mediaStyle';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import utilsMessages from 'utils/messages';
import { FormattedMessage } from 'react-intl';
import Arrow from './Arrow.svg';
import styled from 'styled-components';

interface OwnProps {
  items: any;
  editedItems: {};
  onSetQuantity: (data) => void;
  onDeleteItem: (data) => void;
}

const EditCard = styled(Card)<any>`
  &.ant-card {
    font-size: 14px;
    line-height: 32px;
    box-shadow: none;
    border-top: 1px solid #e8e8e8;
    margin: -16px -24px 0;

    &:first-child {
      border-top: none;
    }
    &:hover {
      background-color: #fff;
    }
  }

  .label {
    color: #b4b4b4;
  }
`;

const Quantity = styled.div`
  display: flex;

  p {
    color: #595959;
  }
`;

const ArrowIcon = styled(SVGIcon)<any>`
  margin: 0 4px;
  width: 16px;

  .injected-svg {
    width: 100%;
    height: 100%;
  }

  ${media.md`
		margin: 0 16pt;
	`};
`;

const Title = styled.h4`
  color: #595959;

  &.delete {
    text-decoration: line-through;
  }
`;

const Note = styled.p`
  font-weight: 300;
  color: #8c8c8c;
`;

const EditRow = styled(Row)<any>``;

const EditOrderItemMobile = (props: OwnProps) =>
  props.items.map((item) => (
    <EditCard key={item.product.id} bordered={false}>
      <Title className={item.isDelete && 'delete'}>{item.product.name}</Title>
      {item.buyerNote && (
        <EditRow>
          <Note>{item.buyerNote}</Note>
        </EditRow>
      )}
      <EditRow type="flex" justify="space-between">
        <p className="label">
          <FormattedMessage {...utilsMessages.quantity} /> (exct, VAT)
        </p>
        <Money>{item.product.price}</Money>
      </EditRow>
      <EditRow type="flex" justify="space-between">
        <p className="label">
          <FormattedMessage {...utilsMessages.quantity} />
        </p>
        <Quantity>
          <p>
            {item.orderedQuantity} {item.product.uom}
          </p>
          <ArrowIcon src={Arrow} />
          <InputNumber
            size="small"
            min={0.001}
            disabled={item.isDelete}
            value={props.editedItems[item.product.id] || item.orderedQuantity}
            onChange={(value) => props.onSetQuantity({ quantity: value, itemId: item.product.id })}
          />
        </Quantity>
      </EditRow>
      {!item.isDelete && (
        <a
          style={{ color: '#FE5043', whiteSpace: 'nowrap' }}
          href="javascript:void(0)"
          onClick={() => props.onDeleteItem(item.product.id)}
        >
          <FormattedMessage {...utilsMessages.remove} />
        </a>
      )}
    </EditCard>
  ));

export default EditOrderItemMobile;
