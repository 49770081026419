/*
 * OrderDetailsPage Messages
 *
 * This contains all the text for the OrderDetailsPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.OrderDetailsPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the OrderDetailsPage container!',
  },
  disapprovalSuccess: {
    id: `${scope}.disapprovalSuccess`,
    defaultMessage: 'Không duyệt đơn hàng thành công',
  },
  declineFailed: {
    id: `${scope}.declineFailed`,
    defaultMessage: 'Không duyệt đơn hàng thất bại',
  },
  updateOrderSuccess: {
    id: `${scope}.updateOrderSuccess`,
    defaultMessage: 'Cập nhật đơn hàng thành công',
  },
  updateOrderFailed: {
    id: `${scope}.updateOrderFailed`,
    defaultMessage: 'Cập nhật đơn hàng thất bại',
  },
});
