/*
 *
 * OrderDetailsPage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/OrderDetailsPage/DEFAULT_ACTION',
  FETCH_ORDER_REQUEST = 'app/OrderDetailsPage/FETCH_ORDER_REQUEST',
  FETCH_ORDER_SUCCESS = 'app/OrderDetailsPage/FETCH_ORDER_SUCCESS',
  FETCH_ORDER_FAILURE = 'app/OrderDetailsPage/FETCH_ORDER_FAILURE',
  CANCEL_ORDER = 'app/OrderDetailsPage/CANCEL_ORDER',
  CANCEL_ORDER_REQUEST = 'app/OrderDetailsPage/CANCEL_ORDER_REQUEST',
  CANCEL_ORDER_SUCCESS = 'app/OrderDetailsPage/CANCEL_ORDER_SUCCESS',
  CANCEL_ORDER_FAILURE = 'app/OrderDetailsPage/CANCEL_ORDER_FAILURE',
  REORDER = 'app/OrderDetailsPage/REORDER',
  REORDER_REQUEST = 'app/OrderDetailsPage/REORDER_REQUEST',
  REORDER_SUCCESS = 'app/OrderDetailsPage/REORDER_SUCCESS',
  REORDER_FAILURE = 'app/OrderDetailsPage/REORDER_FAILURE',
  CONFIRM_ORDER = 'app/OrderDetailsPage/CONFIRM_ORDER',
  CONFIRM_ORDER_REQUEST = 'app/OrderDetailsPage/CONFIRM_ORDER_REQUEST',
  CONFIRM_ORDER_SUCCESS = 'app/OrderDetailsPage/CONFIRM_ORDER_SUCCESS',
  CONFIRM_ORDER_FAILURE = 'app/OrderDetailsPage/CONFIRM_ORDER_FAILURE',
  UPDATE_DELIVERY_SUBMIT = 'app/OrderDetailsPage/UPDATE_DELIVERY_SUBMIT',
  UPDATE_DELIVERY_REQUEST = 'app/OrderDetailsPage/UPDATE_DELIVERY_REQUEST',
  UPDATE_DELIVERY_SUCCESS = 'app/OrderDetailsPage/UPDATE_DELIVERY_SUCCESS',
  UPDATE_DELIVERY_FAILURE = 'app/OrderDetailsPage/UPDATE_DELIVERY_FAILURE',
  APPROVE_ORDER_REQUEST = 'app/OrderDetailsPage/APPROVE_ORDER_REQUEST',
  APPROVE_ORDER_SUCCESS = 'app/OrderDetailsPage/APPROVE_ORDER_SUCCESS',
  APPROVE_ORDER_FAILURE = 'app/OrderDetailsPage/APPROVE_ORDER_FAILURE',
  DISAPPROVE_ORDER_REQUEST = 'app/OrderDetailsPage/DISAPPROVE_ORDER_REQUEST',
  DISAPPROVE_ORDER_SUCCESS = 'app/OrderDetailsPage/DISAPPROVE_ORDER_SUCCESS',
  DISAPPROVE_ORDER_FAILURE = 'app/OrderDetailsPage/DISAPPROVE_ORDER_FAILURE',
  UPDATE_ORDER_REQUEST = 'app/OrderDetailsPage/UPDATE_ORDER_REQUEST',
  UPDATE_ORDER_SUCCESS = 'app/OrderDetailsPage/UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAILURE = 'app/OrderDetailsPage/UPDATE_ORDER_FAILURE',
}

export default ActionTypes;
