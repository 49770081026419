import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the orderDetailsPage state domain
 */

const selectOrderDetailsPageDomain = (state: ApplicationRootState) => {
  return state ? state.orderDetailsPage : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by OrderDetailsPage
 */

const selectOrderDetailsPage = () =>
  createSelector(selectOrderDetailsPageDomain, substate => {
    return substate;
  });

export default selectOrderDetailsPage;
export { selectOrderDetailsPageDomain };
