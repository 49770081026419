import { Modal as AModal, Card } from 'antd';

import DesktopTable from './DesktopTable';
import MobileTable from './MobileTable';
import OrderSummary from './OrderSummary';
import React from 'react';
import { Viewport } from 'components/ViewportProvider';
import { injectIntl } from 'react-intl';
import media from 'utils/mediaStyle';
import messages from 'containers/OrdersPage/messages';
import moment from 'moment';
import styled from 'styled-components';
import translations from 'translations';
import utilsMessages from 'utils/messages';

const Container = styled.section``;
const TableContainer = styled.div`
  .ant-table-body table {
    display: flex;
    flex-direction: column;
    th {
      text-transform: capitalize;
    }
  }
  .ant-table-body .ant-table-row {
    display: block;
    width: 100%;
  }
  .ant-table-row td {
    display: block;
    width: 100%;
  }
  ${media.sm`
    .ant-table-body table {
      display: table;
      flex-direction: column;
    }
    .ant-table-body .ant-table-row {
      display: table-row;
    }
    .ant-table-row td {
      display: table-cell;
      width: 0;
    }
  `};
`;

interface OrderProps {
  order: any;
  onCancelOrder: (value: any) => void;
  onApproveOrder: (value: any) => void;
  onDisapproveOrder: (value: any) => void;
  onConfirmOrder: (value: any) => void;
  onReOrderAll: (value: any) => void;
  onUpdateDelivery: (value: any) => void;
  onEditOrder: () => void;
  intl: any;
  permissions: string[];
}

class OrderDetails extends React.Component<OrderProps, any> {
  public onCancelOrder = () => {
    const cancelOrderMessage = this.props.intl.formatMessage(messages.cancelOrderMessage);
    const yes = this.props.intl.formatMessage(utilsMessages.yes);
    const no = this.props.intl.formatMessage(utilsMessages.no);
    AModal.confirm({
      autoFocusButton: null,
      okButtonProps: {
        danger: true,
      },
      title: cancelOrderMessage,
      okText: yes,
      cancelText: no,
      onOk: () => {
        const { order } = this.props;
        this.props.onCancelOrder({
          id: order.id,
        });
      },
    });
  };
  public onDisapproveOrder = () => {
    const declineOrderMessage = translations(messages.declineOrderMessage);
    const yes = translations(utilsMessages.yes);
    const no = translations(utilsMessages.no);
    AModal.confirm({
      autoFocusButton: null,
      okButtonProps: {
        danger: true,
      },
      title: declineOrderMessage,
      okText: yes,
      cancelText: no,
      onOk: () => {
        const { order } = this.props;
        this.props.onDisapproveOrder({
          orderId: order.id,
        });
      },
    });
  };

  public onApproveOrder = () => {
    const { order } = this.props;
    this.props.onApproveOrder({
      orderId: order.id,
    });
  };

  public onConfirmOrder = () => {
    const { order } = this.props;
    if (moment(order.deliveryTime.start).isAfter(new Date())) {
      const yes = translations(utilsMessages.yes);
      const no = translations(utilsMessages.no);
      AModal.confirm({
        autoFocusButton: null,
        title: translations(messages.confirmDeliveryOrderMessage),
        okText: yes,
        cancelText: no,
        onOk: () => {
          const { order } = this.props;
          this.props.onConfirmOrder({
            id: order.id,
          });
        },
      });
    } else {
      this.props.onConfirmOrder({
        id: order.id,
      });
    }
  };

  public reOrderAll = () => {
    const { order } = this.props;
    this.props.onReOrderAll({
      storeId: order.store.id,
      orderId: order.id,
    });
  };

  public render() {
    const { order, permissions } = this.props;
    if (order.loading) {
      return (
        <div>
          <Container>
            <Card loading={order.loading} style={{ marginBottom: '6pt', backgroundColor: 'white' }} bordered={false} />
            <Card
              bordered={false}
              loading={order.loading}
              style={{ borderBottomRightRadius: 0 }}
              bodyStyle={{ padding: 0 }}
            />
          </Container>
        </div>
      );
    }

    return (
      <Viewport.Consumer>
        {({ width }) => {
          const isMobileSize = width < 768;
          return (
            <Container>
              <Card style={{ marginBottom: '6pt', backgroundColor: 'white' }} bordered={false}>
                <OrderSummary
                  order={order}
                  onCancelOrder={this.onCancelOrder}
                  reOrderAll={this.reOrderAll}
                  onDisapproveOrder={this.onDisapproveOrder}
                  onApproveOrder={this.onApproveOrder}
                  onEditOrder={this.props.onEditOrder}
                  isMobileSize={isMobileSize}
                  onUpdateDelivery={this.props.onUpdateDelivery}
                  permissions={permissions}
                />
              </Card>
              <Card bordered={false} style={{ borderBottomRightRadius: 0 }} bodyStyle={{ padding: 0 }}>
                {isMobileSize ? (
                  <TableContainer>
                    <MobileTable order={order} />
                  </TableContainer>
                ) : (
                  <DesktopTable order={order} />
                )}
              </Card>
            </Container>
          );
        }}
      </Viewport.Consumer>
    );
  }
}

export default injectIntl(OrderDetails);
