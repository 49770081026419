import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styles/styled-components';
import translations from 'translations';
import messages from './messages';
import { Typography, Image } from 'antd';
import media from 'utils/mediaStyle';
import Button from 'components/Button/Button';
import mainBanner from './banner-403.png';

const { Title } = Typography;

const PageContainer = styled.div`
  margin-top: 100px;
  place-items: center;
  ${({ fullHeight }) =>
    fullHeight &&
    `
    min-height: calc(100svh - 106px);
    margin-top: 15px;
  `}
  display: grid;
  overflow: hidden;
  @media (max-width: 1024px) {
    margin-top: 6px;
  }
`;

const PageWrapper = styled.div`
  display: grid;
  gap: 20px;
  justify-items: center;
  ${media.md`
    padding: 24px;
  `};
`;

const SectionHeading = styled(Title)`
  &.ant-typography {
    color: #242527;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }
`;

const SectionButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 22px;
`;

interface Props {
  hasCTA?: boolean;
  fullHeight?: boolean;
}

export const UnauthorizedComponent = ({ hasCTA = true, fullHeight = true }) => {
  return (
    <PageContainer fullHeight={fullHeight}>
      <PageWrapper>
        <Image src={mainBanner} width={333} height="auto" preview={false} alt="403 error main banner" />
        <SectionHeading level={2}>{translations(messages.heading)}</SectionHeading>
        {hasCTA && (
          <SectionButton>
            <Button
              type="primary"
              size="large"
              onClick={() => window.location.replace('/market')}
              style={{
                minWidth: '120px',
                width: 'fit-content',
                fontWeight: 500,
              }}
            >
              {translations(messages.backToMarketButton)}
            </Button>
            <Button
              type="bg-primary"
              size="large"
              onClick={() => window.location.replace('/')}
              style={{
                minWidth: '120px',
                width: 'fit-content',
                fontWeight: 500,
              }}
            >
              {translations(messages.homeButton)}
            </Button>
          </SectionButton>
        )}
      </PageWrapper>
    </PageContainer>
  );
};

const NotAuthorizedPage: React.FC<Props> = (props) => {
  return (
    <>
      <Helmet>
        <title>{`${translations(messages.title)} | Nhà Cung Cấp Kamereo`}</title>
        <meta name="description" content={`${translations(messages.title)}`} />
      </Helmet>
      <UnauthorizedComponent {...props} />
    </>
  );
};

export default NotAuthorizedPage;
