import { Table as ATable } from 'antd';
import SVGIcon from '../SVGIcon/SVGIcon';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Table = styled(ATable)<any>`
  @media screen and (max-width: 500px) {
    .ant-table-thead > tr {
      display: flex;
      justify-content: space-between;
      th:first-child {
        flex: 1;
      }
    }
    .ant-table-row td:not(:last-child) {
      border-bottom: none;
    }
    .ant-table-tbody > tr:hover,
    .ant-table-tbody > tr > td:hover {
      background-color: white !important;
    }
  }
  .ant-table-thead > tr > th {
    background: white;
    color: rgba(0, 0, 0, 0.54);
  }
  p {
    display: flex;
  }
  .label {
    color: rgba(0, 0, 0, 0.54);
    flex: 1;
  }
`;

export const NoteIcon = styled(SVGIcon)<any>`
  ${({ note }) =>
    note === 'true'
      ? `
    path {
      fill: #2e904e;
    }
  `
      : ``};
`;

export const Label = styled.span`
  color: rgba(0, 0, 0, 0.54);
  min-width: 80pt;
  margin-right: 10pt;
`;

export const ExpandIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 8px;
`;

export const OrderContainer = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  box-shadow: 4px 4px 8px rgba(4, 15, 15, 0.12);
`;

export const OrderItem = styled.div<any>`
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  align-items: ${({ align }) => (align ? align : 'center')};
  border-top: 1px solid #e8e8e8;
  box-sizing: border-box;
  transition: all 0.3s ease;
  background-color: white;
  &.header {
    border: none;
    color: rgba(0, 0, 0, 0.54);
    text-transform: capitalize;
  }
  &:hover {
    background-color: #f0f8fb;
  }
  &.header:hover {
    box-shadow: none;
  }
`;

export const ItemCell = styled.div<any>`
  padding: 8px 12px;
  flex: ${({ flex }) => flex || '1 0 0'};
  flex-basis: ${({ fbasis }) => fbasis};
`;

export const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.87);
  &:hover {
    color: #40a9ff;
  }
`;

export const OrderContainerMobile = styled.div``;

export const OrderItemMobile = styled.div`
  padding: 24px;
  border-bottom: 1px solid #e8e8e8;
`;

export const OrderContainerDesktop = styled.div`
  border: 1px solid #e8e8e8;
  margin: 24px;
`;
